import styled, { css } from 'styled-components';
import { colorTypeHelper } from 'utils/colorUtility';

const coloredBackgroundStyles = css`
  ${({ posts }) =>
    posts?.map(
      (post, index) =>
        `
    .slick-slide:nth-child(${index + 1}) {
      .card--cover {
        background: linear-gradient(
          0deg,
          var(${colorTypeHelper[post.toolboxCardColor]}) 0%,
          var(${colorTypeHelper[post.toolboxCardColor]})  60%,
          rgba(255, 255, 255, 0) 60%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
    `,
    )}
  .card--cover {
    img {
      width: 83%;
      margin: auto;
    }

    &:hover {
      .card--cover-image {
        opacity: 1;
      }
    }
  }

  .bookmark-container:hover {
    .card--cover {
      .tool-card--image,
      .card--cover-image {
        opacity: 1;
      }
    }
  }
`;

const Container = styled.div`
  ${({ type }) => type === 'Colored Background' && coloredBackgroundStyles}
  background: ${({ backgroundColor }) => `var(${backgroundColor})`};
  padding: 50px 0;

  .slider-arrow-container .slider-arrow--left-side {
    left: -15px;
  }

  .card-slider-section {
    padding: 0;
    margin: 0;
  }

  .slider-arrow-container .slider-arrow--left-side {
    width: 75px;
    left: 0;
  }

  @media (max-width: 576px) {
    padding: 30px 0;
  }

  ${({ customCss }) => customCss};
`;

export { Container };
