import React from 'react';
import ImageCardSliderSection from 'components/Sections/ImageCardSections/ImageCardSliderSection';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import { colorTypeHelper } from 'utils/colorUtility';

import { Container } from './styles';

const Spade = ({ entries = [], type, query }) => {
  const title = entries.filter((entry) => entry.templateId === 'title')?.[0];
  const styles = entries.filter((entry) => entry.templateId === 'section')?.[0]?.styles;
  const backgroundColor = styles?.backgroundColor;
  const customCss = styles?.customStyling?.customStyling;

  return (
    <Container
      backgroundColor={colorTypeHelper[backgroundColor]}
      customCss={customCss}
      type={type}
      posts={query?.executedQuery}
    >
      <ImageCardSliderSection
        customTitle={<ContentfulRichText richText={title} />}
        sectionsData={query?.executedQuery}
        slideCount={type === 'Colored Background' ? { desktop: 2, tablet: 1, mobile: 1.1 } : null}
      />
    </Container>
  );
};

export default Spade;
